<template>
  <v-container fluid class="content-wrapper">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("SigninOpenClose.title") }}</h4>
      <v-btn icon class="ml-3" @click="getPropertyInfo()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <div v-if="loadingData">
      <v-skeleton-loader type="heading" class="mb-3"></v-skeleton-loader>
      <v-skeleton-loader type="heading" class="mb-5"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line" class="mb-10"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </div>

    <v-form v-else v-model="validation">
      <v-row row wrap>
        <v-col cols="5">
          {{ $t("SigninOpenClose.start_date") }} :
          <v-menu
            ref="start_menu"
            v-model="start_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :rules="rules.date"
                v-model="start_format"
                placeholder="dd/mm/yyyy"
                append-icon="mdi-calendar"
                @click:append="start_menu = true"
                v-bind="attrs"
                v-on="on"
                class="inputtext mt-2"
                color="#1e9ff2"
                outlined
                dense
                readonly
                autofocus
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              color="#1e9ff2"
              v-model="start_date"
              @input="formatStart(start_date)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5">
          {{ $t("SigninOpenClose.end_date") }} :
          <v-menu
            ref="end_menu"
            v-model="end_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :rules="rules.date"
                v-model="end_format"
                placeholder="dd/mm/yyyy"
                append-icon="mdi-calendar"
                @click:append="end_menu = true"
                v-bind="attrs"
                v-on="on"
                class="inputtext mt-2"
                color="#1e9ff2"
                outlined
                dense
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              color="#1e9ff2"
              v-model="end_date"
              :min="min_enddate"
              :show-current="min_enddate"
              @input="formatEnd(end_date)"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-checkbox v-model="limit_date" value="1" color="#1e9ff2" class="ml-2 mr-3">
          <template v-slot:label
            ><div class="mt-2">{{ $t("SigninOpenClose.monday") }}</div></template
          >
        </v-checkbox>
        <v-checkbox v-model="limit_date" value="2" color="#1e9ff2" class="mx-3">
          <template v-slot:label
            ><div class="mt-2">{{ $t("SigninOpenClose.tuesday") }}</div></template
          >
        </v-checkbox>
        <v-checkbox v-model="limit_date" value="3" color="#1e9ff2" class="mx-3">
          <template v-slot:label
            ><div class="mt-2">{{ $t("SigninOpenClose.wednesday") }}</div></template
          >
        </v-checkbox>
        <v-checkbox v-model="limit_date" value="4" color="#1e9ff2" class="mx-3">
          <template v-slot:label
            ><div class="mt-2">{{ $t("SigninOpenClose.thursday") }}</div></template
          >
        </v-checkbox>
        <v-checkbox v-model="limit_date" value="5" color="#1e9ff2" class="mx-3">
          <template v-slot:label
            ><div class="mt-2">{{ $t("SigninOpenClose.friday") }}</div></template
          >
        </v-checkbox>
        <v-checkbox v-model="limit_date" value="6" color="#1e9ff2" class="mx-3">
          <template v-slot:label
            ><div class="mt-2">{{ $t("SigninOpenClose.saturday") }}</div></template
          >
        </v-checkbox>
        <v-checkbox v-model="limit_date" value="0" color="#1e9ff2" class="mx-3">
          <template v-slot:label
            ><div class="mt-2">{{ $t("SigninOpenClose.sunday") }}</div></template
          >
        </v-checkbox>
      </v-row>
      <v-row row wrap>
        <v-col cols="12">
          <div class="card card-shadow pa-5">
            <p>{{ $t("SigninOpenClose.roomtype_rateplan") }}</p>
            <div v-for="(room, index) in all_rooms" :key="room._id">
              <div v-if="room.rate_plan.length > 0">
                <span>{{ room.room_name.value }}</span>
                <v-row>
                  <v-checkbox
                    @click="allRate(index)"
                    v-model="room.choice"
                    value="all"
                    color="#1e9ff2"
                    class="mx-3"
                  >
                    <template v-slot:label
                      ><div class="mt-2">
                        {{ $t("SigninOpenClose.all_rateplan") }}
                      </div></template
                    >
                  </v-checkbox>
                  <v-checkbox
                    v-if="room.rate_plan.length > 1"
                    @click="someRate(index)"
                    v-model="room.choice"
                    value="some"
                    color="#1e9ff2"
                    class="mx-3"
                  >
                    <template v-slot:label
                      ><div class="mt-2">
                        {{ $t("SigninOpenClose.some_rateplan") }}
                      </div></template
                    >
                  </v-checkbox>
                </v-row>
                <div v-if="room.choice === 'some'">
                  <span>
                    <b>{{ $t("SigninOpenClose.rateplan_change_title") }} </b>
                    <span v-if="room.alert" class="error--text">
                      * {{ $t("SigninOpenClose.please_choose_rateplan") }}</span
                    >
                  </span>
                  <v-row>
                    <template v-for="rate in room.rate_plan">
                      <v-checkbox
                        @click="checkRate(index)"
                        v-model="room.rate_plan_price"
                        :key="rate._id"
                        :value="rate._id"
                        color="#1e9ff2"
                        class="mx-3"
                      >
                        <template v-slot:label
                          ><div class="mt-2">{{ rate.value }}</div></template
                        >
                      </v-checkbox>
                    </template>
                  </v-row>
                </div>
                <v-divider v-if="index + 1 != all_rooms.length"></v-divider>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="card card-shadow pa-5">
            <span>{{ $t("SigninOpenClose.setting_roomandrateplan") }}</span>
            <v-radio-group row v-model="status">
              <v-radio color="#1e9ff2" value="active">
                <template v-slot:label>
                  <div class="mt-2">{{ $t("SigninOpenClose.open") }}</div>
                </template>
              </v-radio>
              <v-radio color="#1e9ff2" class="mx-4" value="inactive">
                <template v-slot:label>
                  <div class="mt-2">{{ $t("SigninOpenClose.close") }}</div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-if="!loadingData" class="mb-2">
      <!-- <v-btn depressed dark color="#ff9149" class="ml-2">{{$t("SigninOpenClose.btn_back")}}</v-btn> -->
      <v-btn
        :disabled="!validation"
        :class="start_date && end_date ? 'white--text' : ''"
        @click="checkValidate"
        depressed
        dark
        color="#666ee8"
        class="mx-3"
      >
        {{ $t("SigninOpenClose.btn_save") }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";

export default {
  name: "SigninOpenCloseScreen",
  metaInfo: {
    title: "Open / Close Room",
    titleTemplate: "%s | Aigent",
  },
  // beforeRouteLeave(to, from, next) {
  //   const answer = window.confirm(this.$t("Alert.needtogootherroute"));
  //   if (answer) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // },
  data() {
    return {
      userToken: "",
      propertyID: "",
      rules: {
        date: [(val) => (val || "").length > 0 || "* โปรดเลือกวันที่"],
      },
      all_rooms: {},
      validation: false,
      start_menu: false,
      start_format: "",
      start_date: "",
      min_startdate: "",
      end_menu: false,
      end_format: "",
      end_date: "",
      min_enddate: "",
      limit_date: ["0", "1", "2", "3", "4", "5", "6"],
      status: "active",
      rate_plan_price: [],
      no_rateplan: "",
      loadingData: false,
    };
  },
  created() {
    EventBus.$emit("loadingtillend");
    this.userToken = this.$route.params.userToken;
    this.propertyID = this.$route.params.propertyId;
  },
  mounted() {
    this.getPropertyInfo();
  },
  methods: {
    async getPropertyInfo() {
      this.loadingData = true;
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${this?.propertyID || this?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                this?.userToken ||
                localStorage?.getItem("Token") ||
                this?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          var temp = res.data.result[0];
          var rooms = temp.rooms;
          // console.log('temp', temp)
          // console.log('rooms', rooms)
          var rateplan = temp.rate_plan_price;
          // console.log('rateplans', rateplan)
          this.all_rooms = rooms.map((room) => {
            // console.log("find room", room.room_name.value, room._id);
            var rate_plan = [];
            var rate_plan_price = [];
            rateplan.forEach((rate) => {
              var ratehaveroom = [];
              // console.log("rate id", rate._id);
              rate.rooms.forEach((roominrate, index) => {
                if (roominrate.room) {
                  // console.log('find', room._id)
                  if (room._id === roominrate.room._id) {
                    // console.log("[/] room no.", index, roominrate.room._id);
                    ratehaveroom.push(roominrate.room._id);
                  } else {
                    // console.log("[X] room no.", index, roominrate.room._id);
                  }
                }
              });
              // console.log("rate check", ratehaveroom.length);
              if (ratehaveroom.length !== 0) {
                rate_plan_price.push(rate._id);
                if (rate.name) var rateinfo = { _id: rate._id, value: rate.name };
                else rateinfo = { _id: rate._id, value: rate.rate_plan.value };
                rate_plan.push(rateinfo);
              }
            });
            // console.log("# rate_plan_price", rate_plan_price);
            // console.log('# rate_plan', rate_plan)
            // console.log("= = = = = = = = = = = = = = = = = = = = = =");
            room.room = room._id;
            room.rate_plan = rate_plan;
            room.rate_plan_price = rate_plan_price;
            room.choice = "all";
            room.alert = false;
            return room;
          });
          // console.log("room with rateplan", this.all_rooms);
          this.no_rateplan = this.all_rooms.find(
            (room) => room.rate_plan.length === 0 && room.rate_plan_price.length === 0
          );
          // console.log("room no rateplan", this.no_rateplan);
          if (this.no_rateplan.length === this.all_rooms.length) {
            swal(
              this.$t("Alert.warn_title"),
              "ยังไม่สามารถทำรายการได้ เนื่องจากยังไม่มีข้อมูลเรทแพลน",
              this.$t("Alert.warn_label"),
              {
                button: false,
                timer: 4000,
              }
            );
          }
        }
      } catch (error) {
        console.dir(error.response);
      } finally {
        EventBus.$emit("endloading");
        this.loadingData = false;
      }
    },
    formatStart(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.start_format = `${day}/${month}/${year}`;
      this.start_menu = false;
      var current = new Date(date);
      this.min_enddate = new Date(current.getTime() + 86400000).toLocaleDateString(
        "en-CA"
      );
    },
    formatEnd(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.end_format = `${day}/${month}/${year}`;
      this.end_menu = false;
    },
    checkRate(index) {
      if (
        this.all_rooms[index].rate_plan.length ===
        this.all_rooms[index].rate_plan_price.length
      ) {
        this.all_rooms[index].choice = "all";
      }
    },
    someRate(index) {
      this.all_rooms[index].rate_plan_price = [];
    },
    allRate(index) {
      this.all_rooms[index].rate_plan.forEach((rate) => {
        this.all_rooms[index].rate_plan_price.push(rate._id);
      });
    },
    checkValidate() {
      EventBus.$emit("loadingtillend");
      if (this.no_rateplan === this.all_rooms.length) {
        setTimeout(() => {
          EventBus.$emit("endloading");
        }, 1000);
        swal(
          this.$t("Alert.warn_title"),
          "ยังไม่สามารถทำรายการได้ เนื่องจากยังไม่มีข้อมูลเรทแพลน",
          this.$t("Alert.warn_label"),
          {
            button: false,
            timer: 4000,
          }
        );
      } else {
        const checkroom = [];
        this.all_rooms.forEach((room, index) => {
          if (room.choice === "some" && room.rate_plan_price.length === 0) {
            this.all_rooms[index].alert = true;
            checkroom.push(index);
          }
        });
        // console.log('=', checkroom)
        if (checkroom.length !== 0) {
          setTimeout(() => {
            EventBus.$emit("endloading");
          }, 1000);
          swal(
            this.$t("Alert.warn_title"),
            "กรุณาเลือกเรทแพลน",
            this.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } else {
          // console.log('save')
          this.updateOpenclose();
        }
      }
    },
    async updateOpenclose() {
      var rateObj = [];
      // console.log("all_rooms =", this.all_rooms);
      this.all_rooms.forEach((room) => {
        var data = {
          room: room._id,
          rate_plan_price: room.rate_plan_price,
        };
        rateObj.push(data);
      });
      const path =
        "/calendar/open_or_close_room?property=" +
        `${this?.propertyID || this?.$route?.params?.propertyId}` +
        "&start_date=" +
        this.start_date +
        "&end_date=" +
        this.end_date +
        "&day=" +
        this.limit_date +
        "&status=" +
        this.status;
      // console.log(rateObj);
      // console.log(path);
      try {
        const res = await this.axios.put(process.env.VUE_APP_API + path, rateObj, {
          headers: {
            Authorization:
              this?.userToken ||
              localStorage?.getItem("Token") ||
              this?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || this?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          EventBus.$emit("endloading");
          swal(
            this.$t("Alert.success_title"),
            this.$t("Alert.save_success"),
            this.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        console.dir(error.response);
        EventBus.$emit("endloading");
        swal(
          this.$t("Alert.warn_title"),
          this.$t("Alert.tryagain"),
          this.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        EventBus.$emit("endloading");
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/signin/promotion.css";
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
</style>
